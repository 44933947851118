import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import LoadChunk from './components/util/load-chunk';
import Index from './views/index';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Index hello={123} />,
    loader: (args) => {
      console.log(args);
      return 1;
    }
  },
  {
    path: '/recovery-flow',
    element: <LoadChunk component={import(/* webpackChunkName: "recovery" */'./views/recovery-flow/index')}/>,
  },
  {
    path: '/store-select', // 机型选择
    element: <LoadChunk component={import(/* webpackChunkName: "store-select" */'./views/store-select/index')}/>,
  },
  {
    path: '/sku-detail', // 自检
    element: <LoadChunk component={import(/* webpackChunkName: "sku-detail" */'./views/sku-detail/index')}/>,
  },
  {
    path: '/evaluation-animate', // 评估动画
    element: <LoadChunk component={import(/* webpackChunkName: "evaluation-animate" */'./views/evaluation-animate/index')}/>,
  },
  {
    path: '/evaluation-result', // 评估结果
    element: <LoadChunk component={import(/* webpackChunkName: "evaluation-result" */'./views/evaluation-result/index')}/>,
  },
  {
    path: '/user-verification', // 用户核验
    element: <LoadChunk component={import(/* webpackChunkName: "user-verification" */'./views/user-verification/index')}/>,
  },
  {
    path: '/user-verifi-step2', // 用户核验
    element: <LoadChunk component={import(/* webpackChunkName: "user-verifi-step2" */'./views/user-verifi-step2/index')}/>,
  },
  {
    path: '/verifi-success', // 用户核验
    element: <LoadChunk component={import(/* webpackChunkName: "verifi-success" */'./views/verifi-success/index')}/>,
  },
  {
    path: '/grc-detection', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "grc-detection" */'./views/grc-detection/index')}/>,
  },
  {
    path: '/grc-detection-step2', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "grc-detection-step2" */'./views/grc-detection-step2/index')}/>,
  },
  {
    path: '/data-clear', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "data-clear" */'./views/data-clear/index')}/>,
  },
  {
    path: '/clear-success', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "clear-success" */'./views/clear-success/index')}/>,
  },
  {
    path: '/me', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "me" */'./views/me/index')}/>,
  },
  {
    path: '/my-order', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "my-order" */'./views/my-order/index')}/>,
  },
  {
    path: '/apply-invoice', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "apply-invoice" */'./views/apply-invoice/index')}/>,
  },
  {
    path: '/demo', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "demo" */'./views/demo/index')}/>,
  },
  {
    path: '/protocol', // 合规核验
    element: <LoadChunk component={import(/* webpackChunkName: "protocol" */'./views/protocol/index')}/>,
  },
  {
    path: '*',
    element: <div>not found 123123</div>
  },
]);

const Router = () => {
  return (<RouterProvider router={routes} />);
}

export default Router;
