class Store {
  constructor() {
    this.data = {};
    this.provider = {};
  }

  setData(key, value) {
    this.data[key] = value;
  }

  getData(key) {
    return this.data[key];
  }

  getStore(key) {
    return this.provider[key];
  }

  setStore(key, provider) {
    this.provider[key] = provider;
  }
}

export default new Store();
