import React, {createContext} from 'react';
import {createRoot} from 'react-dom/client';
import Router from './router';
import store from './store';
import 'src/public/css/reset.min.css';
import 'src/public/css/global.less';
import 'antd-mobile/2x/es/global';

const root = createRoot(document.getElementById('app'));

const Provider = createContext(null);

store.setStore('provider', Provider);

root.render(<Provider.Provider value={store.data}>
    <React.StrictMode>
      {/* <App /> */}
      <Router />
    </React.StrictMode>
  </Provider.Provider>);