import React, {useState} from 'react';
import cn from 'classname';
import {NavBar, Button, SafeArea} from 'antd-mobile';
import {StarFill, DownFill, TravelOutline, HandPayCircleOutline, FillinOutline, CheckShieldOutline} from 'antd-mobile-icons';
import './style.less';
import {useNavigate} from 'react-router-dom';
import banner1 from './images/u5.png';
import banner2 from './images/u7.png';
import {gsap} from 'gsap';
import {TextPlugin} from 'gsap/TextPlugin';


gsap.registerPlugin(TextPlugin);

const Index = () => {
  const navigate = useNavigate();
  const [active, setactive] = useState(1);

  const handleClick = (_active) => {
    setactive(_active);
    if (_active === 2) {
      navigate('/me');
    }
  };

  return (<div className={cn('index-page')}>
    {/* <NavBar backArrow={false}>智能终端回收</NavBar> */}
    <div className={cn('content')}>
      <div className={cn('banner')}>
        <div className={cn('banner-content', 'row')}>
          <div className="col">
            <div className="max-tips">最高回收价</div>
            <div className={cn('price')}>
              <span className={cn('unit')}>￥</span>
              <span className={cn('value')}>4000</span>
            </div>
            <div className={cn('add-price')}>
              <span><StarFill /></span>
              <span>限时加价</span>
              <span className="price-add">￥</span>
              <span className="price-add">320</span>
              <div className={cn('tag')}>即将失效</div>
            </div>
          </div>
          <Button color="primary" className={cn('col-center')} onClick={() => {
            navigate('/store-select');
          }}>立即回收</Button>
        </div>
        <div className={cn('banner-footer')}>
          <div className="item">
            <span><TravelOutline /></span>
            <span>顺丰包邮</span>
          </div>
          <div className="arrow"><DownFill /></div>
          <div className="item">
            <span><FillinOutline /></span>
            <span>签收质检</span>
          </div>
          <div className="arrow"><DownFill /></div>
          <div className="item">
            <span><CheckShieldOutline /></span>
            <span>保证隐私</span>
          </div>
          <div className="arrow"><DownFill /></div>
          <div className="item">
            <span><HandPayCircleOutline /></span>
            <span>极速打款</span>
          </div>
        </div>
      </div>
      <div className={cn('sell-type')}>
        <img src={banner1} alt="" />
        {/* <div className={cn('col', 'col-center', 'left')}>1</div>
        <div className={cn('col', 'col-center')}>2</div> */}
      </div>
      <div className="description">
        <img src={banner2} alt="" />
      </div>
      <div className="more row">
        <div className="col left" onClick={() => {
          navigate('/recovery-flow');
        }} />
        <div className="col right">
          <div className="top-item" />
          <div className="btm-item" />
        </div>
      </div>
    </div>

    <div className="index-tab-wrap">
      <div className="index-tab">
        <div className={cn('common-item', {active: active === 1})} onClick={() => {
          handleClick(1);
        }}>首页
        </div>
        <div className="ripple-bg">
          <div className="circle" onClick={() => (navigate('/store-select'))}><span>估价</span></div>
          <div className="ripple1"></div>
          <div className="ripple2"></div>
          <div className="ripple3"></div>
        </div>
        <div className={cn('common-item', {active: active === 2})} onClick={() => {
          handleClick(2);
        }}>我的
        </div>
      </div>
      <SafeArea position="bottom" />
    </div>
  </div>);
};

export default Index;
