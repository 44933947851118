import {useState, useEffect} from 'react';
import {Spin} from 'antd';

const LoadChunk = (props) => {
  const {component} = props;
  const [loading, setloading] = useState(false);
  const [Comp, setComp] = useState(null);

  useEffect(() => {
    if (component) {
      setloading(true);
      component.then((module) => {
        setloading(false);
        setComp(() => module.default);
      }).catch(() => {
        setloading(false);
      });
    }
  }, [component]);

  return Comp ? <Comp /> : (<Spin spinning={loading}>hello</Spin>);
}

export default LoadChunk;